import {Component, Inject} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {FeedbackService} from "../../../services/feedback.service";
import {CommerceService} from "../../../services/commerce.service";
import { BugService } from "../../../services/bug.service";
import {MatIconButton} from "@angular/material/button";

interface CheckResponse {
  title: string;
  loading: boolean | null;
  uk: boolean, // Whether to run this check for UK cars
  ie: boolean, // Whether to run this check for IE cars
  col: 1 | 2,
  error?: string | null;
  result?: {
    tableData?: any; status: "ok" | "warn" | "fail" | null; text?: string[]; data?: any
  }
}

interface CheckResponses {
  [p: string]: CheckResponse
}

@Component({
  selector: "app-feedback",
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatIcon, MatIconButton, MatDialogClose],
  templateUrl: "./feedback.component.html",
  styleUrl: "./feedback.component.scss",
})
export class FeedbackComponent {
  public searchResult: { [key: string]: string | boolean | number | null } | undefined;
  protected checks: CheckResponses = {
    finance: {title: "Finance Status", loading: null, uk: true, ie: true, col: 1},
    stolen: {title: "Stolen Vehicle", loading: null, uk: true, ie: false, col: 1},
    writeoff: {title: "Write-off status", loading: null, uk: true, ie: true, col: 1},
    damages: {title: "Vehicle Damages", loading: null, uk: true, ie: false, col: 1},
    warranty: {title: "Vehicle Warranty", loading: null, uk: true, ie: true, col: 1},
    mileage: {title: "Mileage History", loading: null, uk: true, ie: false, col: 1},
    provenance: {title: "High risk provenance", loading: null, uk: true, ie: true, col: 1},

    imported: {title: "Imported", loading: null, uk: false, ie: true, col: 1},
    equivalent: {title: "Ads for similar vehicles", loading: null, uk: true, ie: true, col: 1},

    owners: {title: "Owner History", loading: null, uk: true, ie: true, col: 2},
    identity: {title: "Vehicle Identity", loading: null, uk: true, ie: true, col: 2},
    technical: {title: "Technical specs", loading: null, uk: true, ie: false, col: 2},
    mot: {title: "MOT History", loading: null, uk: true, ie: false, col: 2},
    nct: {title: "NCT Status", loading: null, uk: false, ie: true, col: 2},
    crw: {title: "Roadworthiness", loading: null, uk: false, ie: true, col: 1},
    plates: {title: "Number Plate History", loading: null, uk: true, ie: true, col: 2},
    taxed: {title: "Tax status", loading: null, uk: true, ie: true, col: 2},
    costs: {title: "Running Costs", loading: null, uk: true, ie: true, col: 2},
    valuation: {title: "Vehicle Valuation", loading: null, uk: true, ie: true, col: 2},

    recall: {title: "Outstanding recalls", loading: null, uk: false, ie: false, col: 2},
    insurance: {title: "Insurance cover", loading: null, uk: false, ie: false, col: 2},

    stolen_ie: {title: "Stolen Vehicle", loading: null, uk: false, ie: true, col: 1},

  };

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: { service: string, vrm: string },
    public feedbackService: FeedbackService,
    protected bug: BugService,
    private dialogRef: MatDialogRef<FeedbackComponent>
  ) {
  }

  protected readonly CommerceService = CommerceService;

  closeFeedback(): void {
    // Close the feedback dialog
    this.dialogRef.close();
  }

}
